import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../containers/SaasModern/Documentation';
import uninstall_1 from '../../../../../../common/src/assets/image/uninstall_1.png';

const Documentation = () => {
  const content = (
    <div id="help">
      <p>
        We're sad to see you go. If there's something that could be improved in
        PrintableCal, or some other reason for uninstalling, please{' '}
        <Link to="/#contact_us_section">contact us</Link> to let us know. We
        really do listen and we'd appreciate any feedback that could help us
        improve our product. It's also possible we could help you with whatever
        issue has prompted you to uninstall. You might be pleasantly surprised
        at how responsive we are to feedback!
      </p>
      <h2>How To Uninstall</h2>
      <ol className="numbered">
        <li>
          Open the Control Panel. If you can't find the Control Panel,{' '}
          <a
            href="http://windows.microsoft.com/en-us/windows-8/where-is-control-panel"
            target="_blank"
            rel="noopener noreferrer"
          >
            instructions can be found here.
          </a>
        </li>
        <li>
          Click the <strong>Uninstall a program</strong> link under the{' '}
          <strong>Programs</strong> category.
          <br />
          <Image alt="" src={uninstall_1} style={{ width: 600 }} />
        </li>
        <li>
          A list of installed programs will be shown. The list is in
          alphabetical order. Click <strong>PrintableCal </strong>and then click
          the <strong>Uninstall </strong>button.
        </li>
        <li>
          A message will appear asking if you're sure you want to remove
          PrintableCal. Click OK to continue uninstalling.
        </li>
        <li>
          PrintableCal will then be removed. No drama. If Microsoft Excel, Word,
          or PowerPoint are currently running, they'll need to be restarted to
          remove the PrintableCal tab from the ribbon menu.
        </li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/How-To/Uninstall-PrintableCal"
      commentsId="commentsplus_how_to_uninstall"
      title="Please Help Us Improve"
      description="PrintableCal can be uninstalled by using the Control Panel."
      keywords="Uninstall PrintableCal"
      content={content}
      showDownload={false}
    />
  );
};

export default Documentation;
